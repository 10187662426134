export const routes = {
	home: '/',
	articles: '/informacje',
	article: '/informacje/:slug',
	rankings: '/rankingi',
	ranking: '/rankingi/:slug',
	methodologies: '/metodologia',
	methodology: '/metodologia/:slug',
	prezentacje: '/prezentacje',
	rankingJury: '/kapitula-rankingu',
	qualityMark: '/znak-jakosci',
	gallery: '/galeria',
	errata: '/errata',
	school: '/szkola/:slug',
};
